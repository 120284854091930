import React from 'react';
import styled from 'styled-components';
import { device } from '../device';

const VideoContainer = styled.div`
  margin: 1rem 1rem;
  
  &>iframe {
    width: 720px;
    height: 400px;
  }

  @media ${device.laptopL} {
    
    &>iframe {
      width: 70vw;
      height: 30vh;
  }

  @media ${device.tablet} {
    margin: 1rem 2rem;
    &>iframe {
      width: 80vw;
      height: 25vh;
  }
`;

export default ({ slice }) => {
  const videoDoc = slice.primary.video;
  const videoUniqueURL = videoDoc.embed_url.split('v=')[1];
  const videoURL = `https://www.youtube.com/embed/${videoUniqueURL}?autoplay=1&showinfo=0&controls=0`;

  const videoSrcDoc = `
  <style>
  *{
      padding:0;
      margin:0;
      overflow:hidden;
  }
  html, body{
      height:100%;
  }
  img, span{
      position:absolute;
      width:100%;
      top:0;
      bottom:0;
      margin:auto;
  }
  span{
      height:1.5em;
      text-align:center;
      font:48px/1.5 sans-serif;
      color:white;
      text-shadow:0 0 0.5em black;
  }
  </style>
  <a href=${videoURL}>
  <img src=${videoDoc.thumbnail_url} alt=${videoDoc.title} />
  <span>▶</span>
  </a>
`;

  return (
    <VideoContainer>
      <iframe
        src={videoURL}
        srcDoc={videoSrcDoc}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={videoDoc.title}
        loading="lazy"
      />
    </VideoContainer>
  );
};
