import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import Video from '../components/slices/video';

// Imports for styling parts of the site.
import { device } from '../components/device';

// Importing compnents used on this Page.
import Layout from '../components/layout';
import SEO from '../components/seo';

// Importing standalone CSS file.
import 'normalize.css';
import '../styles/global.css';

const PortfolioContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5vw;

  @media ${device.tablet} {
    margin: 10vw;
    margin-top: 7.5vh;
  }
`;

const PortfolioTitle = styled.h1`
  margin-top: 5vh;
  margin-left: 1rem;
  border-bottom: 2px solid;
  width: min-content;
`;


const PortfolioDescription = styled.p`
  margin-left: 1rem;
  margin-top: 0;
  text-align: center;
`;

const PortfolioVideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;

const Portfolio = ({ data }) => {
  console.log(data);

  useEffect(() => {
    window.prismic = {
      endpoint: 'https://myrmedia.cdn.prismic.io/api/v2'
    };
  });

  return (
    <Layout page="Portfolio">
      <Helmet>
        <meta charset="utf-8" />
        <title>Portfolio</title>
        <link rel="canonical" href="myrmedia.ltd" />
      </Helmet>
      <PortfolioContainer>
        <SEO title="Portfolio" />
        <PortfolioTitle>Porfolio</PortfolioTitle>
        <PortfolioDescription>{RichText.asText(data.portfolio.allPortfoliopages.edges[0].node.portfolioabouttext)}</PortfolioDescription>
        <PortfolioVideoContainer>
          {data.portfolio.allPortfoliopages.edges[0].node.body.map((video, index) => (
            <Video slice={video} key={index} />
          ))}
        </PortfolioVideoContainer>

      </PortfolioContainer>
    </Layout>
  );
};
export default Portfolio;

export const data = graphql`
query {
  portfolio: prismic {
    allPortfoliopages {
      edges {
        node {
          portfolioabouttext
          body {
            ... on PRISMIC_PortfoliopageBodyVideo {
              type
              label
              primary {
                video
              }
            }
          }
        }
      }
    }
  }
}
`;
